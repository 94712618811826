import React, { useState } from 'react'
import { pageStore } from "state/store-zustand";

const Branding = () => {

    const themeObject = pageStore((state) => state.themeObject)
    const logo = themeObject?.logo


    const style = {
        position: 'absolute',
        top: 10,
        left: 10,
        maxWidth: '140px',
    }

    if (!logo) {
        return null
    }

    return (
        <>
            <img
                className="branding"
                style={style}
                src={logo}
                alt="logo" />
        </>
    )
}

export default Branding;